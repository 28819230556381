import { Routes } from '@angular/router';
import { PathService } from 'src/app/locale/services/path.service';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('../../front/views/layout/layout.component').then(m => m.LayoutComponent),
        children: [

            {
                path: '',
                loadComponent: () => import('src/app/front/views/home/home.component').then(m => m.HomeComponent),
            },
            {
                path: PathService.getRouterPath('contact'),
                loadComponent: () => import('src/app/front/views/contact/contact.component').then(m => m.ContactComponent),
            },
            {
                path: PathService.getRouterPath('about'),
                loadComponent: () => import('src/app/front/views/about/about.component').then(m => m.AboutComponent),
            },
            {
                path: PathService.getRouterPath('services') + '/:url',
                loadComponent: () => import('src/app/front/views/services/services.component').then(m => m.ServicesComponent),
            },
            {
                path: '**',
                redirectTo: '',
            },
        ]
    },
];
