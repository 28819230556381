import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './routes';
import { environment } from 'src/environments/environment';
import { provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { scrollConfig } from './scroll-config';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            routes,
            withInMemoryScrolling(scrollConfig),
        ),
        importProvidersFrom([
            BrowserAnimationsModule,
        ]),
        {
            provide: LOCALE_ID,
            useValue: environment.locale.id
        },
        provideClientHydration(withNoHttpTransferCache()),
        provideHttpClient(withFetch())
    ]
};
